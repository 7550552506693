<template>
  <TheFrame/>
</template>

<script>
import TheFrame from './components/TheFrame.vue'

export default {
  name: 'App',
  components: {
    TheFrame
  },
}
</script>

<style>
</style>
