<template>
  <div class="frame">
		<div class="frame__left">
			<header class="header">
				<a class="header__logo" href="/">
					<img src="@/assets/img/logo.png" alt="">
				</a>
				<div class="header__phone">
					<div class="header__phone-desc">
						Номер телефона
					</div>
					<a class="header__phone-link" href="tel:+74993229001">
						+7 (499) 322-90-01
					</a>
				</div>
			</header>
			<div class="frame__left-content">
				<h1 class="title-main frame__title">
					Онлайн калькулятор
				</h1>
				<ul class="frame__list">
					<li
						v-for="(item, index) in calcs" :key="index" 
						class="frame__list-item">
						<a 
							class="frame__list-link" 
							href="#"
							:class="theme == item.name?'frame__list-link_active':''"
							@click.prevent="setTheme(item.name)">
							{{item.label}}
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="frame__right">
			<div class="info-section">
				<h2 class="title-default">
					Независимая оценка и экспертиза
				</h2>
				<div class="info-section__text">
					ООО «Независимая оценочная компания «РЭО групп» оказывает услуги по оценке движимого и недвижимого имущества. Рассчитайте стоимость и отправьте заявку.
				</div>
			</div>
			<div class="calc-section">
				<VueMultiselect 
					v-model="mobileSelect.value"
					:options="mobileSelect.values" 
					:preselect-first="true"
					@select="setTheme($event.value)" 
					:show-labels="false"
					:searchable="false"
					class="frame__mob-select" 
					label="label" 
					:allow-empty="false" 
					track-by="value"
					></VueMultiselect>

				<template v-for="(item, key) in calcs" :key="key">
					<form class="frame__content calc" v-if="theme == item.name" @submit.prevent="presubmit">
						<div class="calc__title frame__question">
							{{ item.title }}
						</div>
						<template
							v-for="(item, index) in item.items"
							:key = index>

							<template v-if="index > 0">
								<div
									v-if="item.label"
									class="calc__lined-text">
									<span class="calc__lined-text-inner">
										{{ item.label }}
									</span>
								</div>
								<div
									v-else
									class="calc__line"></div>
							</template>

							<template
								v-for="(field, index) in item.fields"
								:key = index>
								<calc-elm 
									:item="field"
									@coef-change = "coefChange"
									@set-range = 'setRangeValue(field, $event)' 
									@check-update = "checkUpdate(field)"/>



								<div v-if = "field.items" class="calc__child">
									<template v-for = "(el, index) in field.items" :key="index">
										<calc-elm
											:item = "el"
											@update-price-corrector="updatePriceCorrector($event, el)"
											@open-modal = 'openModal($event)'
											@radio-update = "radioChange(el, field)"
											@check-update = "checkUpdate(el, item)"
											@inline-input = "inlineInput($event, el, field)"/>
									</template>
								</div>


								<button
									v-if="field.name == 'rooms'" 
									@click="addApartment(field)" type="button" class="calc__add-btn">
									+ Добавить комнату
								</button>
							</template>
						</template>

						<div class="calc__line"></div>

						<div class="calc__final-price">
							<div class="calc__final-price-desc">
								Стоимость услуг
							</div>
							<div class="calc__final-price-value">
								{{item.total}} руб
							</div>
						</div>
						<button class="btn-default calc__submit">
							{{ item.btnText }}
						</button>
						<div class="calc__important">
							Данный расчет стоимости носит информационный характер и не является публичной офертой (ст. 437 ГК РФ).
						</div>
					</form>
				</template>
			</div>
			<div class="gallery-section">
				<h2 class="calc__title frame__question">Сертификаты нашего эксперта</h2>
				<FancyboxGallery
					:options="{
						Carousel: {
							infinite: false,
						},
					}"
				>
					<a
						v-for="(item, index) in gallery"
						:key="index"
						class="gallery-items__item" 
						data-fancybox="gallery" 
						:data-caption="item.name"
						:href="item.src">
						<div class="gallery-items__item-image">
							<img :src="item.src"/>
						</div>
						<div class="gallery-items__item-name">
							{{ item.name }}
						</div>
					</a>
				</FancyboxGallery>
			</div>
		</div>
	</div>
	<div class="modals">
		<div 
			class="modal modal-order"
			:class="modalOrder.active?'modal_active':''"
			id="modal-order">
			<div class="modal__overlay" @click="modalOrder.active = false"></div>
			<div class="modal__inner modal-order__inner">
				<button class="modal__close btn-close" @click="modalOrder.active = false"></button>
				<h2 class="modal-order__title">Оформление заявки</h2>
				<form class="modal-order__form" @submit.prevent="submit">
					<div class="modal-order__inputs">
						<label 
							class="modal-order__field" 
							:class="input.required?'modal-order__field_required':''"
							v-for="(input, index) in modalOrder.inputs" 
							:key="index">
							<div class="modal-order__label">
								{{ input.name }}
							</div>
							<input 
								v-if="input.type == 'input'"
								v-model="input.value" 
								class="modal-order__input"
								:class="input.invalid?'modal-order__input_invalid':''"
								v-maska
								:data-maska="input.mask"
								data-maska-eager
								@input="validate(input)"
								:required="input.required"
								type="text" >
							<textarea 
								v-if="input.type == 'textarea'"
								v-model="input.value"
								class="modal-order__textarea" ></textarea>

						</label>
					</div>
					<button class="btn-default modal-order__btn">
						Оформить заявку
					</button>
					<div class="modal-order__agree">
						<p>
							Мы гарантируем сохранность ваших персональных данных и не будет передавать ваши контакты третьим лицам.
						</p>
						<p>
							Нажимая на кнопку «Оформить заявку», вы даете согласие на <a href="#">обработку своих персональных данных</a>
						</p>
					</div>
				</form>
			</div>
		</div>
		<div 
			class="modal modal-success"
			:class="modalSuccess.active?'modal_active':''"
			id="modal-success">
			<div class="modal__overlay" @click="modalSuccess.active = false"></div>
			<div class="modal__inner modal-success__inner">
				<button class="modal__close btn-close" @click="modalSuccess.active = false"></button>
				<h2 class="modal-success__title">Заявка успешно принята</h2>
				<p class="modal-success__text">
					Наш менеджер свяжется с вами
				</p>
			</div>
		</div>
		<div 
			class="modal modal-we"
			:class="modalStation.active?'modal_active':''"
			id="modal-station">
			<div class="modal__overlay" @click="modalStation.active = false"></div>
			<div class="modal__inner modal-we__inner">
				<button class="modal__close btn-close" @click="modalStation.active = false"></button>
				<h2 class="modal-we__title">Офис</h2>
				<div class="modal-we__content">
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							Фактический адрес:
						</div>
						<div class="modal-we__content-value">
							г. Москва, м.Партизанская, Измайловское шоссе д.71, гостиница "Измайлово", корпус Гамма, 4 этаж, оф.9г4
						</div>
					</div>
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							Телефон/факс:
						</div>
						<a class="modal-we__content-value" href="tel:+7 (499) 322-90-01">
							+7 (499) 322-90-01
						</a>
					</div>
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							Время работы:
						</div>
						<div class="modal-we__content-value">
							с 9:00 до 18:00 (Пн-Пт)
						</div>
					</div>
				</div>
				<iframe 
					class="modal-we__map"
					:src="modalStation.active?'https://yandex.ru/map-widget/v1/?um=constructor%3A499c65e32bfe6c02f5dd46a59159796095d264e05cb14f9a21004a0cbbf85f80&amp;source=constructor':''"></iframe>
			</div>
		</div>
		<div 
			class="modal modal-we"
			:class="modalOffice.active?'modal_active':''"
			id="modal-station">
			<div class="modal__overlay" @click="modalOffice.active = false"></div>
			<div class="modal__inner modal-we__inner">
				<button class="modal__close btn-close" @click="modalOffice.active = false"></button>
				<h2 class="modal-we__title">Офис</h2>
				<div class="modal-we__content">
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							Фактический адрес:
						</div>
						<div class="modal-we__content-value">
							Москва, м. Павелецкая, 3-й Монетчиковский пер., 11, стр. 1
						</div>
					</div>
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							Телефон/факс:
						</div>
						<a class="modal-we__content-value" href="tel:+7 (499) 322-90-01">
							+7 (499) 322-90-01
						</a>
					</div>
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							E-mail:
						</div>
						<a class="modal-we__content-value" href="mailto:info@prestig-ocenka.ru">
							info@prestig-ocenka.ru
						</a>
					</div>
					<div class="modal-we__content-row">
						<div class="modal-we__content-label">
							Время работы:
						</div>
						<div class="modal-we__content-value">
							с 9:00 до 18:00 (Пн-Пт)
						</div>
					</div>
				</div>
				<iframe 
					class="modal-we__map"
					:src="modalOffice.active?'https://yandex.ru/map-widget/v1/?um=constructor%3Aeecf2b5fc62b4704bbe88e6a52f19791c07b98cdf6ba3435d269e7ca07bce912&amp;source=constructor':''"></iframe>
			</div>
		</div>
	</div>
</template>

<script>
import CalcElm from './CalcElm.vue'
import VueMultiselect from 'vue-multiselect'
import FancyboxGallery from './FancyboxGallery.vue'

export default {
	name: 'TheFrame',
	components: {
		CalcElm,
		VueMultiselect,
		FancyboxGallery
	},
	data() {
		return {
			calcs: [
				{
					name: 'auto',
					label: 'Независимая автоэкспертиза',
					title: 'Расчет стоимости оценки после ДТП',
					btnText: 'Заказать оценку ущерба',
					items: [
						{
							name: 'main',
							fields: [
							{
								name: 'rate',
								label: 'Степень повреждения',
								type: 'range',
								tooltip: `Легкая - до 5 элементов<br>
									Средняя - до 10 элементов<br>
									Большая - до 30 элементов<br>
									Тотальная - свыше 30 элементов<br>

									*Повышающий коэффициент для грузового, спец. техники, эксклюзивного, раритетного  транспорта от 1 до 2. Детали уточняйте у менеджера.`,
								value: 0,
								values: [
									{
										name: 'Малая',
									},
									{
										name: 'Средняя',
									},
									{
										name: 'Большая',
									},
									{
										name: 'Тотальная',
									}
								]
							},
							{
								name: 'view',
								label: 'Осмотр и составление акта',
								type: 'checkbox',
								tooltip: `Акт осмотра - это документ, который составляется при осмотре автомобиля, в присутствии заинтересованных лиц.<br>
									В акте осмотра фиксируются все повреждения автомобиля, также там указываются способы их устранения (замена, или ремонт).`,
								active: true,
								uncheckable: true,
								items: [
									{
										name: 'view-item',
										label: `Офис`,
										modal: {
											name: 'м.Партизанская',
											link: 'Station'
										},
										type: 'radio',
										price: '500',
									},
									{
										name: 'view-item',
										label: 'Офис',
										modal: {
											name: 'м. Павелецкая',
											link: 'Office'
										},
										type: 'radio',
										price: '500',
										active: true
									},
									{
										name: 'view-item',
										label: 'Выезд специалиста в пределах МКАД',
										type: 'radio',
										price: '1 500',
									},
									{
										name: 'view-item',
										label: 'Выезд за МКАД',
										km: '',
										type: 'radio',
										minprice: '1 500',
										price: '1 500',
									},
								]
							},
							{
								name: 'calc',
								label: 'Расчёт и оформление',
								type: 'checkbox',
								tooltip: `Если в дальнейшем вы планируете обращаться в страховую компанию с претензией, или же в суд с иском о взыскании со страховой компании полной величины ущерба, то вам необходимо заключение эксперта-техника.`,
								items: [
									{
										name: 'calc-item',
										label: `Калькуляция`,
										type: 'radio',
										price: '2 500',
										prices: ['2 500', '3 000', '3 500', '4 000'],
										tooltip: `Калькуляция – определение затрат (стоимость услуг материалов и запасных частей) на восстановление поврежденного автомобиля.<br>
											Калькуляция не является Отчетом об оценке, и следовательно, документом доказательного значения.<br>
											На калькуляцию не распространяются требования Закона №135-ФЗ «Об оценочной деятельности».`,
									},
									{
										name: 'calc-item',
										label: 'Заключение эксперта-техника',
										type: 'radio',
										price: '4 000',
										prices: ['4 000', '4 500', '5 000', '5 500'],
										tooltip: `Письменное сообщение эксперта о результатах проведенного исследования по поставленным вопросам и обоснование представленных выводов.`,
									},
								]
							},
							]
						},
						{
							name: 'expertise',
							label: 'Автотехническая экспертиза',
							fields: [
								{
									name: 'inspection',
									label: 'Автотехническая экспертиза',
									type: 'text',
									tooltip: `Некачественный ремонт ДВС у дилера. <br>Если дилер отрицает свою вину, мы производим экспертизу, с полным разбором ДВС, установлением неисправности, причино-следственную связь, и на выходе виновника.`,
								},
								{
									name: 'inspection',
									label: 'Осмотр поврежденного узла/агрегата',
									type: 'checkbox',
									price: '15 000',
									tooltip: `До 5 часов в пределах МКАД. <br>
									Входит полное сопровождение клиента на осмотре, фотофиксация, контроль за разбором/деффектовкой узлов и агрегатов, предварительная оценка причин и неисправностей. `,
								},
								{
									name: 'draw-exp-opinion-akkp',
									label: 'Составление экспертного заключения по АКПП',
									type: 'checkbox',
									tooltip: `С дефектовкой и заключением в пределах МКАД<br>
														Цена зависит от сложности. <br>
														В пределах МКАД.`,
									price: '30 000',
									originPrice: '30 000'
								},
								{
									name: 'draw-exp-opinion-dvs',
									label: 'Составление экспертного заключения по ДВС',
									type: 'checkbox',
									tooltip: `Цена зависит от сложности. <br>В пределах МКАД.`,
									items: [
										{
											name: 'draw-exp-opinion-dvs-item',
											label: 'А класс',
											type: 'radio',
											price: '30 000',
											tooltip: `Например, киа пиканто, хендай и тд`,
										},
										{
											name: 'draw-exp-opinion-dvs-item',
											label: 'В класс',
											type: 'radio',
											price: '30 000',
											tooltip: `Например, хендай солярис, киа рио и тд`,
										},
										{
											name: 'draw-exp-opinion-dvs-item',
											label: 'С класс',
											type: 'radio',
											price: '30 000',
											tooltip: `Например, киа сид, форд фокус и тд`,
										},
										{
											name: 'draw-exp-opinion-dvs-item',
											label: 'D клас',
											type: 'radio',
											price: '45 000',
											tooltip: `Например, Камри, Теана, Пассат и тд`,
										},
										{
											name: 'draw-exp-opinion-dvs-item',
											label: 'Премиум',
											type: 'radio',
											price: '60 000',
										},
									]
								},
								{
									name: 'other-nodes',
									label: 'Остальные узлы',
									type: 'checkbox',
									tooltip: `Подвеска, электрика, подушки и тд`,
									price: '30 000',
								},
								{
									name: 'trace',
									label: 'Трасологическая экспертиза',
									type: 'checkbox',
									// tooltip: ``,
									price: '',
									items: [
										{
											name: 'trace-item',
											label: 'В пределах МКАД',
											type: 'radio',
											price: '15 000',
										},
										{
											name: 'trace-item',
											label: 'Осмотр места ДТП в пределах МКАД',
											type: 'radio',
											price: '22 000',
										},
										{
											name: 'trace-item',
											label: 'Осмотр места ДТП за пределами МКАД',
											type: 'radio',
											price: '22 000',
											minprice: '22 000',
											km: '',
										},
									]
								},
							]
						},
						{
							name: 'extra',
							label: 'Дополнительные опции',
							fields: [
								{
									name: 'auto-view2',
									label: 'Расчет утраты товарной стоимости',
									type: 'checkbox',
									price: '1 500',
									prices: ['1500', ' 1750', '2 000', '2 250'],
									tooltip: `Расчет Утраты Товарной Стоимости (УТС)<br>
										Восстановленный автомобиль после ДТП стоит дешевле такого же, но не поврежденного. Разница в их стоимости и есть УТС автомобиля.`,
								},
								{
									name: 'auto-view3',
									label: 'Расчет стоимости годных остатков',
									type: 'checkbox',
									price: '1 500',
									tooltip: `Годные остатки автомобиля после ДТП – это неповрежденные узлы и детали, годные к дальнейшей эксплуатации и которые можно демонтировать с поврежденного автомобиля и продать.`,
								},
								{
									name: 'auto-view3',
									label: 'Определение рыночной стоимости',
									type: 'checkbox',
									price: '1 500',
								},
								{
									name: 'auto-view3',
									label: 'Отправка телеграммы',
									type: 'checkbox',
									price: '700',
									tooltip: `Для ведения дела вам может понадобиться отправка 1-2 телеграмм.`,
								},
							]
						}
					],
					total: 0
				},
				{
					name: 'apartment',
					label: 'Экспертиза ущерба квартиры',
					title: 'Экспертиза ущерба квартиры',
					btnText: 'Заказать оценку ущерба',
					items: [
						{
							name: 'main',
							fields: [ 
								{
									name: 'rooms',
									label: 'Выберите поврежденные элементы',
									type: 'text',
									active: true,
									count: 1,
									items: [
										{
											name: 'rooms-item',
											label: 'Прихожая',
											type: 'checkbox',
											price: '2 000'
										},
										{
											name: 'rooms-item',
											label: 'Коридор',
											type: 'checkbox',
											price: '2 000'
										},
										{
											name: 'rooms-item',
											label: 'Ванная комната',
											type: 'checkbox',
											price: '2 000',
										},
										{
											name: 'rooms-item',
											label: 'Туалет',
											type: 'checkbox',
											price: '2 000'
										},
										{
											name: 'rooms-item',
											label: 'Кухня',
											type: 'checkbox',
											price: '2 000'
										},
										{
											name: 'rooms-item',
											label: 'Комната 1',
											type: 'checkbox',
											price: '2 000'
										},
									]
								},
							]
						},
						{
							name: 'extra',
							fields: [
								{
									name: 'smeta',
									label: 'Смета',
									type: 'checkbox',
									price: '2 000'
								},
								{
									name: 'expert-conclusion',
									label: 'Экспертное заключение',
									type: 'checkbox',
									price: '3 500'
								},
								{
									name: 'expert-conclusion-full',
									label: 'Экспертное заключение от сертифицированного Судебного эксперта',
									type: 'checkbox',
									price: '4 500'
								},
								{
									name: 'apartment-telegram',
									label: 'Отправка телеграммы',
									type: 'checkbox',
									price: '700',
								},
								{
									name: 'apartment-claim',
									label: 'Cоставление претензии + отправка',
									type: 'checkbox',
									price: '3 500',
								},
								{
									name: 'place',
									label: 'Где вы находитесь?',
									type: 'text',
									items: [
										{
											name: 'place-item',
											label: 'Москва, в пределах МКАД',
											type: 'radio',
											price: '0'
										},
										{
											name: 'place-item',
											label: 'За пределами МКАД',
											type: 'radio',
											km: '',
											minprice: '1 000',
											price: '1 000',
										},
									]
								},
							]
						}
					],
					total: 0
				},
				{
					name: 'legal',
					label: 'Юридические услуги',
					title: 'Расчет стоимости услуг',
					btnText: 'Оформить заявку',
					items: [
						{
							name: 'main',
							fields: [
								{
									name: 'consultation',
									label: 'Консультация',
									type: 'checkbox',
									price: '0',
								},
								{
									name: 'claim',
									label: 'Претензия',
									type: 'checkbox',
									price: '3 500',
								},
								{
									name: 'claim-full',
									label: 'Претензия+исковое в суд',
									type: 'checkbox',
									price: '10 000',
								},
								{
									name: 'claim-full',
									label: 'Полное сопровождение',
									type: 'checkbox',
									price: '10 000',
									items: [
										{
											name: 'claim-full-item',
											label: 'до 3х комнат',
											type: 'radio',
											price: '50 000'
										},
										{
											name: 'claim-full-item',
											label: 'до 5 комнат',
											type: 'radio',
											price: '60 000'
										},
										{
											name: 'claim-full-item',
											label: 'от 5 и выше',
											type: 'radio',
											price: '70 000'
										},
									]
								},
								{
									name: 'claim-full',
									label: 'Пакет под ключ.Только юр. лица (управляющим компаниям, жкх, жку и тд) - работаем за свой счет, после взыскания денег оплачиваете от 20 до 30% с взысканной суммы',
									type: 'checkbox',
									price: '',
								},
								{
									name: 'docs',
									label: 'Подача документов в страховую компанию или РСА',
									type: 'checkbox',
									price: '6 000',
									tooltip: `Мы экономим ваше время и гарантируем правильность оформления всех документов.<br>
										При выборе этой услуги, мы вместо вас:<br>
										- Поедем в страховую компанию<br>
										- Отстоим в очередях<br>
										- Сдадим документы, согласуем дату и время осмотра<br>
										- Проконтролируем ход рассмотрения документов<br>

										В случае, если в установленные в законе сроки выплата не будет произведена, мы сообщаем вам об этом и обратимся в суд.`
								},
								{
									name: 'representative',
									label: 'Представление интересов в суде',
									type: 'checkbox',
									active: true,
									coef: [
										{
											name: 'г.Москвы',
											value: '0',
											active: true
										},
										{
											name: 'Московской области',
											value: '1 000'
										},
									],
									items: [
										{
											name: 'representative-item',
											label: 'В одном судебном заседании',
											type: 'radio',
											minPrice: '6 000',
											price: '6 000',
											coefSlave: true,
											tooltip: `При заказе вы получаете:<br>
												- подготовку правовой позиции;<br>
												- участие в одном судебном заседании.`, 
											active: true
										},
										{
											name: 'representative-item',
											label: 'От заявления до заседания',
											type: 'radio',
											price: '15 000',
											tooltip: `Данная услуга предоставляется только для исков стоимостью от 50 000 до 120 000 рублей.<br>
												При заказе вы получаете:<br>
												- подготовку искового заявления;<br>
												- подача в суд;<br>
												- участие в предварительном и одном судебном заседании.`
										},
										{
											name: 'representative-item',
											label: 'Ведение дела «под ключ», при цене иска: до 50 тыс.руб.',
											type: 'radio',
											price: '15 000',
											priceCorrector : {
												index: '0',
												items: [
													{
														label: 'до 50 тыс.руб.',
														point: '50',
														value: '15 000'
													},
													{
														label: '50—70 тыс.руб.',
														point: '50-70',
														value: '20 000'
													},
													{
														label: '70—100 тыс.руб.',
														point: '70-100',
														value: '25 000'
													},
													{
														label: '100—150 тыс.руб.',
														point: '100-150',
														value: '30 000'
													},
													{
														label: '150—350 тыс.руб.',
														point: '150-350',
														value: '40 000'
													},
													{
														label: 'свыше 350 тыс.руб.',
														point: '350',
														value: '50 000'
													}
												],
											},
											tooltip: `При заказе вы получаете:<br>
												- Подготовку искового заявления;<br>
												- Подача иска в суд;<br>
												- Участие во всех судебных заседаниях в суде первой инстанции;<br>
												- Получение решения и исполнительного листа.`
										},
									]
								}
							]
						},
						{
							name: 'extra',
							fields: [
								{
									name: 'telegramma',
									label: 'Отправка телеграммы',
									type: 'checkbox',
									price: '700',
									tooltip: `Вам понадобится эта услуга, только если ответчиком выступает физическое лицо!<br>
										Как правило, при рассмотрении гражданских дел мы отправляем 1-2 телеграммы.`
								},
								{
									name: 'list',
									label: 'Получение исполнительного листа и предъявление его в',
									type: 'checkbox',
									items: [
										{
											name: 'list-item',
											label: 'Банк',
											type: 'radio',
											price: '3000'
										},
										{
											name: 'list-item',
											label: 'Службу судебных приставов',
											type: 'radio',
											price: '3 000',
										},
										{
											name: 'list-item',
											label: 'Обе инстанции',
											type: 'radio',
											price: '5 000',
										},
									]
								},
							]
						}
					],
					total: 0
				},
				{
					name: 'build',
					label: 'Строительная экспертиза',
					title: 'Расчет стоимости услуг',
					btnText: 'Заказать оценку ущерба',
					items: [
						{
							name: 'main',
							fields: [
								{
									name: 'acceptance',
									label: 'Приемка квартиры (Мск + Ближайшие города)',
									type: 'checkbox',
									price: '5 000',
									tooltip: `Входит осмотр, замеры, составление акта приемки с недостатками.`
								},
								{
									name: 'pretrial',
									label: 'Досудебная строительная экспертиза',
									type: 'checkbox',
									price: '15 000',
									tooltip: `Входит приемка, плюс расчет ущерба в ПО Смета, оформлением заключения занимается Судебный эксперт.`
								},
								{
									name: 'turnkey-court',
									label: 'Суд под ключ',
									type: 'checkbox',
									price: '',
									tooltip: `Цена зависит от суммы ущерба.<br>
														Мы сами подаем в суд, готовим все претензии и иски.<br> 
														Также можем подать в суд, где мы делаем "Судебную экспертизу с подтверждением ущерба".
									`,
									items: [
										{
											name: 'turnkey-court-item',
											label: 'До 400 т. руб',
											type: 'radio',
											tooltip: `Гарантированно взыщем 30% от суммы ущерба. <br>В противном случае заплатим сами.`,
											price: ''
										},
										{
											name: 'turnkey-court-item',
											label: '400-500 т. руб',
											type: 'radio',
											tooltip: `Гарантированно взыщем 25% от суммы ущерба. <br>В противном случае заплатим сами.`,
											price: ''
										},
										{
											name: 'turnkey-court-item',
											label: 'От 500 т. руб',
											type: 'radio',
											tooltip: `Гарантированно взыщем 20% от суммы ущерба. <br>В противном случае заплатим сами.`,
											price: ''
										},
									]
								},
								{
									name: 'representative',
									label: 'Представление интересов в суде',
									type: 'checkbox',
									active: true,
									items: [
										{
											name: 'representative-item',
											label: 'В одном судебном заседании',
											type: 'radio',
											minPrice: '6 000',
											price: '6 000',
											coefSlave: true,
											tooltip: `При заказе вы получаете:<br>
												- подготовку правовой позиции;<br>
												- участие в одном судебном заседании.`, 
											active: true
										},
										{
											name: 'representative-item',
											label: 'От заявления до заседания',
											type: 'radio',
											price: '15 000',
											tooltip: `Данная услуга предоставляется только для исков стоимостью от 50 000 до 120 000 рублей.<br>
												При заказе вы получаете:<br>
												- подготовку искового заявления;<br>
												- подача в суд;<br>
												- участие в предварительном и одном судебном заседании.`
										},
									]
								}
							]
						},
					],
					total: 0
				}
			],
			modalOrder: {
				active: false,
				inputs: [
					{
						name: 'Имя',
						value: '',
						type: 'input'
					},
					{
						name: 'Телефон',
						value: '',
						mask: '+7 (###) ###-##-##',
						type: 'input',
						required: true
					},
					{
						name: 'E-mail',
						value: '',
						type: 'input'
					},
					{
						name: 'Комментарий',
						value: '',
						type: 'textarea'
					},
				]
			},
			modalStation: {
				active: false
			},
			modalOffice: {
				active: false
			},
			modalSuccess: {
				active: false
			},
			mobileSelect: {},
			theme: 'auto',
			gallery: [
				{
					name: 'Диплом оценщика',
					src: require('@/assets/img/certificates/diplom_otsenshchika.jpg')
				},
				{
					name: 'Диплом Судебного Автоэксперта',
					src: require('@/assets/img/certificates/grigorevskij_sv_1.jpg')
				},
				{
					name: 'Диплом эксперта',
					src: require('@/assets/img/certificates/watermarked_2.jpg')
				},
				{
					name: 'Диплом эксперта',
					src: require('@/assets/img/certificates/watermarked_mikhaylov_diplom_eksp.jpg')
				},
				{
					name: 'Палата судебных экспертов "Судекс"',
					src: require('@/assets/img/certificates/watermarked_svidetelstvo.jpg')
				},
				{
					name: 'Свидетельство СРО',
					src: require('@/assets/img/certificates/svidetelstvo_sro.jpg')
				},
				{
					name: 'Выписка эксперта',
					src: require('@/assets/img/certificates/watermarked_mikhaylov_vypiska_eksp.jpg')
				},
				{
					name: 'Выписка эксперта',
					src: require('@/assets/img/certificates/watermarked_img_0063.jpg')
				},
				{
					name: 'Сертификат судебный эксперт',
					src: require('@/assets/img/certificates/watermarked_3.jpg')
				},
				{
					name: 'Сертификат',
					src: require('@/assets/img/certificates/watermarked_sertifikat_134.jpg')
				},
				{
					name: 'Сертификат',
					src: require('@/assets/img/certificates/watermarked_sertifikat_133.jpg')
				},
				{
					name: 'Сертификат',
					src: require('@/assets/img/certificates/watermarked_134.jpg')
				},
				{
					name: 'Сертификат',
					src: require('@/assets/img/certificates/watermarked_133.jpg')
				},
			]
		}
	},
	created() {
		this.setMobileSelectValies();
	},
	mounted() {
		this.calcTotalPrice();
	},
	methods: {
		setMobileSelectValies() {
			this.mobileSelect.values = [],
			this.calcs.forEach(element => {
				this.mobileSelect.values.push({label: element.label, value: element.name})
			});

			this.mobileSelect.value = {label: this.calcs[0].label, value: this.calcs[0].name}
		},
		checkUpdate(item, parent = null) {
			item.active = !item.active;

			if (parent&&parent.items) {
				parent.active = false;

				parent.items.forEach(el => {
					if (el.active) {
						parent.active = true;
						return;
					}
				})
			}

			if (item.uncheckable) {
				item.active = true;
			}

			if (item.items) {
				item.items.forEach(element => {
					element.active = false;
				});
				if (item.active) {
					item.items[0].active = true;
				}
			}

			this.calcTotalPrice();
		},
		radioChange(item, parent = null) {
			if (parent&&parent.items) {
				parent.active = true;

				parent.items.forEach(element => {
					element.active = false;
				});
			}

			item.active = true;

			this.calcTotalPrice();
		},
		getIndex(array, prop, name) {
			let index = array.findIndex(function(item) {
				return item[prop] == name;
			});

			return index;
		},
		calcTotalPrice() {
			let total = 0;
			
			const format = num => new Intl.NumberFormat('ru-RU').format(num);

			if (this.theme === 'apartment') {
				let indexTheme = this.getIndex(this.calcs, 'name', 'apartment');

				let indexMain = this.getIndex(this.calcs[indexTheme].items, 'name', 'main');
				let indexExtra = this.getIndex(this.calcs[indexTheme].items, 'name', 'extra');

				let indexRooms = this.getIndex(this.calcs[indexTheme].items[indexMain].fields, 'name', 'rooms');
				let indexEcf = this.getIndex(this.calcs[indexTheme].items[indexExtra].fields, 'name', 'expert-conclusion-full');
				let indexTg = this.getIndex(this.calcs[indexTheme].items[indexExtra].fields, 'name', 'apartment-telegram');
				let indexClaim = this.getIndex(this.calcs[indexTheme].items[indexExtra].fields, 'name', "apartment-claim")

				let rooms = this.calcs[indexTheme].items[indexMain].fields[indexRooms].items;
				let ecf = this.calcs[indexTheme].items[indexExtra].fields[indexEcf];
				let tg = this.calcs[indexTheme].items[indexExtra].fields[indexTg];
				let claim = this.calcs[indexTheme].items[indexExtra].fields[indexClaim];

				let activeRooms = rooms.filter(item => {
					return (item.active == true && item.label.indexOf('Комната') >= 0);
				})

				if (ecf.active && tg.active && (activeRooms.length >= 3)) {
					claim.price = '2 500';
				}
				else {
					claim.price = '3 500';
				}
			}
			
			if (this.theme === 'auto') {
				let indexTheme = this.getIndex(this.calcs, 'name', 'auto');

				let indexExpertise = this.getIndex(this.calcs[indexTheme].items, 'name', 'expertise');

				let indexAkpp = this.getIndex(this.calcs[indexTheme].items[indexExpertise].fields, 'name', 'draw-exp-opinion-akkp');
				let indexDvs = this.getIndex(this.calcs[indexTheme].items[indexExpertise].fields, 'name', 'draw-exp-opinion-dvs');

				let kpp = this.calcs[indexTheme].items[indexExpertise].fields[indexAkpp];
				let dvs = this.calcs[indexTheme].items[indexExpertise].fields[indexDvs];

				if (kpp.active && dvs.active) {
					kpp.price = format(parseInt(kpp.originPrice.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), '')) / 2);
				}
				else {
					kpp.price = kpp.originPrice;
				}

				// console.log(kpp.price);
			}
			
			this.calcs[this.getIndex(this.calcs, 'name', this.theme)].items.forEach(item => {
				item.fields.forEach(field => {
					if (field.active) {
						if (field.items) {
							field.items.forEach(child => {
								if (child.active) {
									if (child.price) {
										total += parseInt(child.price.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), ''));
									}
								}
							})
						}
						else {
							if (field.price) {
								total += parseInt(field.price.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), ''));
							}
						}
					}
				});
			});

			this.calcs[this.getIndex(this.calcs, 'name', this.theme)].total = format(total);
		},
		damageRate() {
			// тут селектор захардкожен. Если поменяется порядок, или еще что-то, то все сломается. Надо проверять при изменении data
			let index = this.calcs[this.getIndex(this.calcs, 'name', 'auto')].items[0].fields[0].value;

			this.calcs[this.getIndex(this.calcs, 'name', 'auto')].items.forEach(item => {
				item.fields.forEach(field => {
					if (field.prices) {
						field.price = field.prices[index];
					}

					if (field.items) {
						field.items.forEach(el => {
							if (el.prices) {
								el.price = el.prices[index];
							}
						})
					}
				})
			})
		},
		openModal(event) {
			this['modal' + event].active = true;
		},
		setRangeValue(item, value) {
			item.value = value;
			this.damageRate();
			this.calcTotalPrice();
		},
		inlineInput(event, el, item) {
			const format = num => new Intl.NumberFormat('ru-RU').format(num);

			if (item.items) {
				item.items.forEach(element => {
					element.active = false;
				});
			}
			item.active = true;
			el.active = true;
			el.km = event.target.value;


			el.price = format(parseInt(el.minprice.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), '')) + Math.ceil(el.km / 10) * 300);

			this.calcTotalPrice();
		},
		addApartment(field) {
			field.items.push({
				name: 'place-item',
				label: 'Комната ' + ++field.count,
				type: 'checkbox',
				price: '2 000'
			})
		},
		validate(input) {
			if (input.required) {
				if (input.name == 'Телефон') {
					input.invalid = false;

					if (input.value.length < 18) {
						input.invalid = true;
					}
				}
			}
		},
		presubmit() {
			this.modalOrder.active = true;
		},
		submit() {
			let invalid = false;

			this.modalOrder.inputs.forEach(el => {
				if (el.required && el.invalid) {
					invalid = true;
				}
			})

			if (invalid) return;

			let FD = new FormData();
			

			let calc = this.calcs[this.getIndex(this.calcs, "name", this.theme)];

			FD.append('calc', calc.label)

			calc.items.forEach(item => {
				item.fields.forEach(element => {
					if (element.active) {
						FD.append(element.label, element.price)

						if (element.items) {
							element.items.forEach(child => {
								if (child.active) {
									FD.append(child.label, child.price)

									if (child.priceCorrector) {
										FD.append('Представление интересов в суде', child.priceCorrector.items[child.priceCorrector.index].label)
									}
								}
							})
						}

						if (element.coef) {
							element.coef.forEach(child => {
								if (child.active) {
									FD.append(element.label, child.name)
								}
							})
						}
					}
				});
			});


			FD.append('total', calc.total)

			this.modalOrder.inputs.forEach(element => {
				if (element.value.length) {
					FD.append(element.name, element.value)
				}
			});
			
			let object = {};
			FD.forEach((value, key) => object[key] = value);
			let json = JSON.stringify(object);


			// console.log(json);
			fetch('/send.php', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: json
			})
			.then(response => {
				console.log(response);
				this.modalOrder.active = false;
				this.modalSuccess.active = true;
			})
			.catch(error => {
				console.log(error);
			});
		},
		setTheme(theme) {
			this.theme = theme;
			this.calcTotalPrice();
		},
		coefChange(el, item) {
			item.coef.forEach(element => {
				element.active = false;
			})

			el.active = true;

			const format = num => new Intl.NumberFormat('ru-RU').format(num);

			item.items.forEach(element => {
				if (element.coefSlave) {
					element.price = format(parseInt(element.minPrice.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), '')) + parseInt(el.value.replace(new RegExp(" ",'g'), '').replace(new RegExp(" ",'g'), '')));
				}
			})

			this.calcTotalPrice();
		},
		updatePriceCorrector(event, el) {
			el.price = el.priceCorrector.items[event].value;
			el.priceCorrector.index = event;
			el.label = 'Ведение дела «под ключ», при цене иска: ' + el.priceCorrector.items[event].label;

			this.calcTotalPrice();
		}
	}
}
</script>

<style scoped>
.info-section {
    max-width: 660px;
    margin: 0px auto 40px auto;
}

.gallery-section {
    margin: auto;
}
</style>
