import './assets/scss/main.scss'

import { createApp } from 'vue'
import App from './App.vue'

import VueTippy from 'vue-tippy'
import { vMaska } from "maska"

const myapp = createApp(App);


myapp.use(
  VueTippy,
  // optional
  {
		// allowHTML: true,
    directive: 'tippy', // => v-tippy
    //component: 'tippy', // => <tippy/>
  }
)
myapp.directive("maska", vMaska)


myapp.mount('#app');
